module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5ZQ7TV2","includeInDevelopment":false,"routeChangeEventName":"WEB_APP_ROUTE_CHANGE","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Win back the losses of your business","short_name":"Growyze","start_url":"/","background_color":"#1a1750","theme_color":"#1a1750","display":"standalone","icon":"/codebuild/output/src431955933/src/growyze-front-end/static/images/icon/growyze-icon.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"importWorkboxFrom":"local","globDirectory":"public","globPatterns":["*/**"],"cacheId":"gatsby-plugin-offline","skipWaiting":true,"clientsClaim":true,"maximumFileSizeToCacheInBytes":10000000}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
